import React, {useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import {setTimer} from "../store/mainReducer";

const MainGame = ({go}) => {

    const dispatch = useDispatch()
    
    document.addEventListener("visibilitychange", function(){
	if (document.hidden) {
        }
    });

    const bgCity = new Image();
    bgCity.src = 'images/bgcity.png'
    const bgForest = new Image();
    bgForest.src = 'images/bgforest.png'
    const carImg = new Image();
    carImg.src = 'images/car.png'
    const stopMarkImg = new Image();
    stopMarkImg.src = 'images/stopmark.png'
    const limitMarkImg = new Image();
    limitMarkImg.src = 'images/mark.png'
    const billboardImg1 = new Image();
    billboardImg1.src = 'images/billboard1.png'
    const billboardImg2 = new Image();
    billboardImg2.src = 'images/billboard2.png'


    const canvasRef = useRef(null)
    let animationID

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')

        function renderCanvas() {
            loop(ctx)
            animationID = window.requestAnimationFrame(renderCanvas)
        }

        renderCanvas()

        return () => {
            window.cancelAnimationFrame(animationID)
        }
    }, [loop])

    let isGaming = false

    class Timer {
        constructor(x, y, font, color) {
            this.x = x
            this.y = y
            this.font = font
            this.color = color
            this.startHour = 0
            this.startMin = 0
            this.startSec = 0
            this.hour = 0
            this.min = 0
            this.sec = 0

        }

        getTime() {
            if (this.startSec === 0) {
                this.startHour = Date.now()
                this.startMin = Date.now()
                this.startSec = Date.now()
            }
            this.sec = Math.floor((Date.now() - this.startSec) / 1000)
            if (this.sec >= 60) {
                this.startSec = Date.now()
            }
            this.min = Math.floor((Date.now() - this.startMin) / 1000 / 60)
            if (this.min >= 60) {
                this.startMin = Date.now()
            }
            this.hour = Math.floor((Date.now() - this.startHour) / 1000 / 3600)
            if (this.hour >= 24) {
                this.startHour = Date.now()
            }
        }

        stopTime() {
            this.startHour = 0
            this.startMin = 0
            this.startSec = 0
            this.hour = 0
            this.min = 0
            this.sec = 0
        }

        draw(ctx) {
            let timeSec = new Intl.NumberFormat('en-IN', {minimumIntegerDigits: 2}).format(this.sec)
            let timeMin = new Intl.NumberFormat('en-IN', {minimumIntegerDigits: 2}).format(this.min)
            let timeHour = new Intl.NumberFormat('en-IN', {minimumIntegerDigits: 2}).format(this.hour)
            ctx.fillStyle = this.color
            ctx.font = this.font
            ctx.fillText(`${timeHour}:${timeMin}:${timeSec}`, this.x, this.y)
        }
    }

    class AllImages {
        constructor(image, x, y, wid, heig) {
            this.image = image
            this.x = x
            this.y = y
            this.wid = wid
            this.heig = heig
            this.speed = 0
        }

        moving() {
            this.x -= this.speed
            if (this.x <= -1017) {
                this.x = 1017
                this.speed = 0
            }
        }

        draw(context) {
            context.drawImage(this.image, this.x, this.y, this.wid, this.heig)
        }
    }

    class Billboard {
        constructor(image1, image2, x, y, wid, heig) {
            this.bill1 = image1
            this.bill2 = image2
            this.x = x
            this.y = y
            this.wid = wid
            this.heig = heig
            this.number = 1
            this.speed = 0
        }
        moving() {
            this.x -= this.speed
            if (this.x <= -1017) {
                this.x = 1017
                this.speed = 0
                this.number+=1
            }
            if (this.number === 3) {this.number = 1}
        }

        draw(context) {
            if (this.number === 1) {
                context.drawImage(this.bill1, this.x, this.y, this.wid, this.heig)
            } else {
                context.drawImage(this.bill2, this.x, this.y, this.wid, this.heig)
            }
        }
    }

    class Car {
        constructor(image, x, y, speed, wid) {
            this.image = image
            this.x = x
            this.y = y
            this.speed = speed
            this.wid = wid
            this.stPos = x
        }

        drive() {
            this.x += this.speed
            if (this.x >= this.stPos + 3) {
                this.speed = -this.speed
            }
            if (this.x <= this.stPos) {
                this.speed = -this.speed
            }
        }

        draw(context) {
            context.drawImage(this.image, this.x, this.y, this.wid, 55)
        }
    }

    class Bg {
        constructor(bg1, bg2, x, y, speed, wid, boost) {
            this.bg1 = bg1
            this.bg2 = bg2
            this.x = x
            this.y = y
            this.speed = speed
            this.boost = boost
            this.wid = wid
            this.x2 = this.wid
            this.isgas = false
            this.isIncrease = false
            this.timerOut = 0
            this.timerCount = 0
            this.curSpeed = Math.floor(this.speed * 10)
            this.limit = 60
            this.circle1 = 1
            this.circle2 = 1
        }

        moving() {
            if (this.isIncrease) {
                this.timeOut()
            }
            if (this.speed >= 12) {
                this.speed = 12
            }
            this.curSpeed = Math.floor(this.speed * 10)
            if (this.isgas) {
                this.speed += this.boost
            } else {
                this.speed -= this.boost
            }

            this.x -= this.speed
            this.x2 -= this.speed
            if (this.x <= -this.wid) {
                this.x = this.wid + this.x2 - this.speed
                this.circle1+=1;
            }
            if (this.x2 <= -this.wid) {
                this.x2 = this.wid + this.x - this.speed
                this.circle2=this.circle2+1
            }
            if (this.circle1 === 7){this.circle1=1}
            if (this.circle2 === 7){this.circle2=1}
        }

        timeOut() {
            if (this.timerOut === 0) {
                this.timerOut = Date.now()
            }
            this.timerCount = Math.floor((Date.now() - this.timerOut) / 1000)
        }

        draw(context) {
            if (this.circle1 < 3) {
                context.drawImage(this.bg1, this.x, this.y)
            } else {
                context.drawImage(this.bg2, this.x, this.y)
            }
            if (this.circle2 < 3) {
                context.drawImage(this.bg1, this.x2, this.y)
                bg.limit = 60
            } else {
                context.drawImage(this.bg2, this.x2, this.y)
                bg.limit = 90
            }


        }
    }

    class Text {
        constructor(x, y, text, value, color, fontsize) {
            this.x = x
            this.y = y
            this.text = text
            this.value = value
            this.color = color
            this.fontsize = fontsize
        }

        changeValue(value) {
            this.value = value
        }

        draw(context) {
            context.beginPath()
            context.fillStyle = this.color
            context.font = this.fontsize
            context.fillText(this.text + this.value, this.x, this.y)
            context.fill()
            context.closePath()
        }

    }

    let clientWidth = document.querySelector('.page-wrapper').clientWidth
// Инициализация объектов
    let bg = new Bg(bgCity, bgForest, 0, 0, 2, 1016, 0.03)
    let car = new Car(carImg, clientWidth*0.25, 270, 0.15, 141)
    let billboard = new Billboard(billboardImg1, billboardImg2, 1020, 145, 180, 143)
    let limitMark = new AllImages(limitMarkImg, clientWidth*0.8, 20, 60, 60)
    let stopMark = new AllImages(stopMarkImg, clientWidth*0.24, 100, 350, 200)
    let killhour = new Text(clientWidth*0.5, 73, 'км/ч', '', 'white', 'normal 20px Noto Sans')
    let currentSpeed = new Text(clientWidth*0.5, 50, '', 0, 'white', 'bold 40px Noto Sans')
    let timeOutText = new Text(stopMark.x+160, 220, '', bg.timerCount, 'red', 'bold 24px Noto Sans')
    let limitSpeed = new Text(limitMark.x+15, 60, '', bg.limit, 'black', 'bold 26px Noto Sans')
    let timer = new Timer(clientWidth*0.1, 60, 'bold 28px Noto Sans', 'white')
// Прослушиваем события

    document.addEventListener('keydown', speedUp);
    document.addEventListener('keyup', speedDown);

    function speedUp() {
        isGaming = true
        bg.isgas = true
    }

    function speedDown() {
        bg.isgas = false
    }

    function update() {
        if (isGaming) {
            timer.getTime()
            car.drive()
            bg.moving()
            billboard.moving()
            currentSpeed.changeValue(bg.curSpeed)
            timeOutText.changeValue(5 - bg.timerCount)
            limitSpeed.changeValue(bg.limit)
            if (bg.speed <= 0) {
                stopGame()
            }
            if (bg.curSpeed >= bg.limit) {
                bg.isIncrease = true
                if (bg.timerCount >= 5) {
                    stopGame()
                }
            } else {
                bg.isIncrease = false
                bg.timerOut = 0
            }
            if (bg.circle1 === 2 || bg.circle1 === 5) {
                billboard.speed = bg.speed
            }
        }
    }

    function render(ctx) {
        bg.draw(ctx)
        timer.draw(ctx)
        currentSpeed.draw(ctx)
        killhour.draw(ctx)
        billboard.draw(ctx)
        car.draw(ctx)
        limitMark.draw(ctx)
        limitSpeed.draw(ctx)
        if (bg.curSpeed >= bg.limit || bg.curSpeed <= 0) {
            stopMark.draw(ctx)
            timeOutText.draw(ctx)
        }

    }


    function stopGame() {
        dispatch(setTimer({hour:timer.hour, min:timer.min, sec:timer.sec}))
        timer.stopTime()
        isGaming = false
        car.speed = 0
        bg.speed = 0
        bg.boost = 0
        bg.curSpeed = 0
        timeOutText.changeValue('')
        go('results')
    }

    document.addEventListener("visibilitychange", function(){
        if (document.hidden) {
            stopGame();
        }
    });
// ЦИКЛ
    function loop(ctx) {
        update()
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height) // clear canvas
        render(ctx)
    }

    return (
        <div id='game'>
                <canvas ref={canvasRef} id="canvas" width='750' height='350'/>
            <div id='game_buttons'>
                <button className={'button_clear stop_btn'} onClick={stopGame}><img className='img_buttons' src="images/stopbtn.png"
                                                                           alt=""/></button>
                <button className={'button_clear gas_btn'} onTouchStart={speedUp} onTouchEnd={speedDown} onMouseDown={speedUp}
                        onMouseUp={speedDown}>
                    <img className='img_buttons' src="images/gas.png" alt="" style={{pointerEvents: 'none'}}/></button>
            </div>
            <div className='footer_logo'><img className='logo_michelin' src="images/logo_michelin.png" alt=""/></div>
            <div className='footer_logo_mobile'><img className='logo_michelin' src="images/logo_michelin_mobile.png" alt=""/></div>
        </div>
    );
};

export default MainGame;
