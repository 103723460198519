import React, {useEffect, useState} from 'react';

const Init = ({go}) => {

  const [progress, setProgress] = useState('progress-10');

  useEffect(() => {
    setProgress('progress-100')
    setTimeout(()=>{
        goHome();
    }, 3000);
  }, [progress]);


    function goHome(){
        go('home')
    }

    return (
          <div className="px-2 px-md-3" id="progress">
            <div id="loading_bg">
              <div id="loading_fg" className={progress}></div>
            </div>
          </div>
    );
};

export default Init;
