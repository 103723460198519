import React from 'react'
import Home from './panels/Home'
import Init from "./panels/Init";
import MainGame from "./panels/MainGame";
import Results from "./panels/Results";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "./store/mainReducer";


function App() {
    const dispatch = useDispatch()
    const activePanel = useSelector(state => state.main.activePanel)


    let content = []
    let wrapper_class = 'init'

    function go (id) {
        dispatch(dispatch(setActivePanel(id)))
    }

    if (activePanel==="home"){
        wrapper_class = 'home'
        content.push(<Home go={go}/>)
    } else if (activePanel==='init'){
        wrapper_class = 'init'
        content.push(<Init go={go}/>)
    } else if (activePanel==='game'){
        wrapper_class = 'game'
        content.push(<MainGame go={go}/>)
    } else if (activePanel==='results'){
        wrapper_class = 'results'
        content.push(<Results go={go}/>)
    }


  return (
    <div className={'page-wrapper '+wrapper_class}>
        {content}
    </div>
  );
}

export default App;