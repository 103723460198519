import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name:'main',
    initialState: {
        timer: {hour:0, min:0, sec:0},
        activePanel: 'init',
    },
    reducers:{
        setTimer(state, action) {
            state.timer = action.payload
        },
        setActivePanel(state,action) {
            state.activePanel = action.payload
        },
    }
})

export default mainSlice.reducer
export const {setTimer,setActivePanel} = mainSlice.actions
